import { Box, Tip, Text, Heading } from "grommet";
import styled from "styled-components";
import badgeTypes from "@organisms/badges/types";
import BadgeIcon from "@organisms/badges/icon";
import { useCoin } from "@organisms/coin/provider";
import { applicableBadges } from "@organisms/badges/types";
import withLink from "./withLink";

const Container = withLink(styled(Box)`
  &:hover img {
    transform: scale(1.1);
  }
`);

const Tooltip = ({ type }) => {
  const { title, description } = badgeTypes[type];

  return (
    <Box
      width={{ max: "250px" }}
      background="#26272C"
      animation={{ type: "fadeIn", duration: 600, delay: 400 }}
      round="xsmall"
      pad="small"
      gap="xsmall"
      border={{ side: "all", color: "#44444466" }}
    >
      <Heading
        margin="none"
        level="3"
        size="xsmall"
        color="light-4"
        textAlign="center"
      >
        {title}
      </Heading>
      <Text size="small" color="light-4" textAlign="center">
        {description}
      </Text>
    </Box>
  );
};

function Badge({ type, ...rest }) {
  return (
    <Tip content={<Tooltip type={type} />} plain {...rest}>
      <Container>
        <BadgeIcon size="24px" type={type} />
      </Container>
    </Tip>
  );
}

export default function Badges({ slug }) {
  const { badges } = useCoin(slug);
  const setOfBagdes = new Set(badges);

  return (
    <Box gap="xxsmall" direction="row" justify="center">
      {applicableBadges.map((type) => (
        <Box width="24px" height="24px" key={type}>
          {setOfBagdes.has(type) ? (
            <Badge type={type} />
          ) : (
            <Text color="#4C5D88" size="medium">
              -
            </Text>
          )}
        </Box>
      ))}
    </Box>
  );
}
